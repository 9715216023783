import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Layout,
  CategoriesWidget,
  RecentPostsWidget,
  PostEntry,
  SEO,
} from '../components'

const CategoryTemplate = ({ data }) => {
  const { wordpressCategory: category, allWordpressPost } = data
  return (
    <Layout>
      <SEO title={`${category.name}`} />
      <div className="flex justify-between p-8">
        <div className="w-full lg:w-2/3 mr-6">
          <h1>Category: {category.name}</h1>

          {allWordpressPost.nodes &&
            allWordpressPost.nodes.map((post, index) => (
              <PostEntry key={index} post={post} />
            ))}
        </div>
        <div className="w-full lg:w-1/3">
          <RecentPostsWidget />
          <CategoriesWidget />
        </div>
      </div>
    </Layout>
  )
}

CategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CategoryTemplate

export const pageQuery = graphql`
  query GET_CATEGORY($id: String!) {
    wordpressCategory(id: { eq: $id }) {
      id
      name
      slug
    }
    allWordpressPost(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
    ) {
      nodes {
        ...PostEntryFragment
      }
    }
  }
`
